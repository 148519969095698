/* global $ */
import SliderWidget from '../../slider/script'

export default class CorporationsCarouselWidget {
  get targetSelector() {
    return '.js-carousel-here'
  }

  get deleteSelector() {
    return '.js-delete-carousel'
  }

  constructor() {
    this.$target = $(this.targetSelector)
    this.carouselUrl = this.$target.data('carousel-path')
    if (!this.carouselUrl) return
    this.loadSlider()
  }

  loadSlider() {
    $.ajax({
      url: this.carouselUrl,
      data: this.searchData(),
    })
      .done(this.sliderLoadedCallback.bind(this))
      .fail(this.deleteCarousel.bind(this))
  }

  sliderLoadedCallback(result) {
    const $carousel = $(result).children()

    if ($(result).find('article').length > 0) {
      this.$target.empty().append($carousel)

      this.slider = new SliderWidget()
    } else {
      this.deleteCarousel()
    }
  }

  deleteCarousel() {
    $(this.deleteSelector).remove()
  }

  searchData() {
    return {
      city_name: this.$target.data('searchDataCityName'),
      city_public_id: this.$target.data('searchDataCityPublicId'),
      state_slug: this.$target.data('searchDataStateSlug'),
      latitude: this.$target.data('searchDataLatitude'),
      longitude: this.$target.data('searchDataLongitude'),
      city_filter_active: this.$target.data('searchDataCityFilterActive'),
      list_in_city: this.$target.data('searchDataListInCity'),
    }
  }
}
