/* eslint-disable no-undef */
export default class OnCookiebotConsent {
  constructor() {
    this.initListener()
  }

  initListener() {
    // handle on accept
    window.addEventListener('CookiebotOnAccept', () => {
      if (Cookiebot.consent.marketing) {
        this.loadConvert()
      }
    })
  }

  loadConvert() {
    const script = document.createElement('script')
    script.src = '//cdn-4.convertexperiments.com/v1/js/100411983-100412268.js'
    script.async = true
    document.head.appendChild(script)
  }
}
